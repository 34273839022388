import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './css/fontawesome/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'mdb-react-ui-kit/dist/mdb-react-ui-kit.esm.js';
import './css/app.css';
import { registerLicense } from '@syncfusion/ej2-base';
import FirebasePushNotification from "./helpers/FirebasePushNotification";

registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0BjWXxWc3FXQ2FU;Mgo+DSMBMAY9C3t2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0BjWXxWc3FXQGhZ');


const firebaseConfig = {
  apiKey: "AIzaSyDTy1GuENtoX-XY7NbsIZ-1vta-jFBMU24",
  authDomain: "dog-friendly-42d2e.firebaseapp.com",
  projectId: "dog-friendly-42d2e",
  storageBucket: "dog-friendly-42d2e.firebasestorage.app",
  messagingSenderId: "1066350366897",
  appId: "1:1066350366897:web:597b61b85207f641a05290",
  measurementId: "G-M70M2PEN07"
};
const vapidKey = "YOUR_VAPID_KEY";

const firebasePush = new FirebasePushNotification(firebaseConfig);

const setupPushNotifications = async () => {
  const permissionGranted = await firebasePush.requestPermission();
  if (permissionGranted) {
    const token = await firebasePush.getToken(vapidKey);
    console.log("FCM Registration Token:", token);
  }
};

firebasePush.onMessageListener((payload) => {
  alert(`New notification: ${payload.notification.title}`);
});

setupPushNotifications();



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
