import Cookies from "js-cookie";

export const getAppVersion = () => {
  return "0.1.2";
};

export const getAppVersionFromLocalStorage = () => {
  if (window.localStorage.getItem("AppVersion") !== null) {
    const data = JSON.parse(window.localStorage.getItem("AppVersion"));
    return data;
  }

  return null;
};

export const setAppVersion = (appVersion) => {
  try {
    window.localStorage.setItem("AppVersion", JSON.stringify(appVersion));
    return true;
  } catch (error) {
    return false;
  }
};


export const getFirebaseToken = () => {
  try {
    return Cookies.get("mkmdevicetoken");
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const setFirebaseToken = (token) => {
  try {
    if (token) {
      Cookies.set("mkmdevicetoken", token, { expires: 9999 });
      return true;
    } else
      return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUser = () => {
  if (window.localStorage.getItem("CurrentUser") != null) {
    var data = JSON.parse(window.localStorage.getItem("CurrentUser"));
    return data;
  }
  else
    return null;
};

export const setUser = (User) => {
  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const setEditId = (DataEditId) => {
  try {
    window.localStorage.setItem("DataEditId", JSON.stringify(DataEditId));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getEditId = () => {
  if (window.localStorage.getItem("DataEditId") != null) {
    var data = JSON.parse(window.localStorage.getItem("DataEditId"));
    return data;
  }
  else
    return null;
};

export const setDeviceInfo = (data) => {
  try {
    window.sessionStorage.setItem("AppDeviceInfo", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getDeviceInfo = () => {
  try {
    return window.sessionStorage.getItem("AppDeviceInfo");
  } catch (e) {
    console.error(e);
    return "";
  }
};



export const setFilterData = (data) => {
  console.log(data);
  try {
    window.sessionStorage.setItem("DF_Filter", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getFilterData = () => {
  try {
    if (window.sessionStorage.getItem("DF_Filter") != null) {
      var data = JSON.parse(window.sessionStorage.getItem("DF_Filter"));
      console.log(data);
      return data;
    }
    else
      return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
