import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

class FirebasePushNotification {

  //const firebaseConfig = {
  //  apiKey: "AIzaSyDTy1GuENtoX-XY7NbsIZ-1vta-jFBMU24",
  //  authDomain: "dog-friendly-42d2e.firebaseapp.com",
  //  projectId: "dog-friendly-42d2e",
  //  storageBucket: "dog-friendly-42d2e.firebasestorage.app",
  //  messagingSenderId: "1066350366897",
  //  appId: "1:1066350366897:web:597b61b85207f641a05290",
  //  measurementId: "G-M70M2PEN07"
  //};

  constructor(firebaseConfig) {
    this.app = initializeApp(firebaseConfig);
    this.messaging = getMessaging(this.app);
  }

  /**
   * Request user permission for push notifications.
   * @returns {Promise<boolean>} Returns true if permission is granted, otherwise false.
   */
  async requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        return true;
      } else {
        console.warn("Notification permission denied.");
        return false;
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return false;
    }
  }

  /**
   * Get the FCM registration token for the current device.
   * @returns {Promise<string | null>} The FCM token or null if unable to retrieve it.
   */
  async getToken(vapidKey) {
    try {
      const token = await getToken(this.messaging, { vapidKey });
      if (token) {
        console.log("FCM Token:", token);
        return token;
      } else {
        console.warn("No registration token available. Request permission to generate one.");
        return null;
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
      return null;
    }
  }

  /**
   * Listen for foreground messages.
   * @param {Function} callback - Callback to handle incoming messages.
   */
  onMessageListener(callback) {
    onMessage(this.messaging, (payload) => {
      console.log("Message received in foreground:", payload);
      if (callback) callback(payload);
    });
  }
}

export default FirebasePushNotification;
