import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { GetFirebaseToken } from '../../helpers/FirebaseHelper';
import { getAppVersion } from '../../helpers/lookup';

const Footer = () => {
  const navigate = useNavigate();

  const [showOffscreen, setShowOffscreen] = useState(false);

  const toggle = () => setShowOffscreen(!showOffscreen);

  const forceReload = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Generate a cache-busting URL
    const url = `${window.location.origin}${window.location.pathname}?t=${new Date().getTime()}`;

    if (isIOS) {
      // iOS-specific reload using `replace` to avoid history clutter
      window.location.replace(url);
    } else {
      // For other platforms, use standard reload with cache busting
      if ('serviceWorker' in navigator) {
        // Check if a Service Worker is active
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          if (registrations.length > 0) {
            // Reload with a cache-busting query
            window.location.href = url;
          } else {
            // Standard reload if no Service Worker
            window.location.reload();
          }
        });
      } else {
        // Fallback for platforms without Service Worker support
        window.location.href = url;
      }
    }
  }

  const navigateTo = (path) => {
    setShowOffscreen(false);
    navigate(path);
  }

  const enablePushNotifications = () => {
    console.log("getting token")
    GetFirebaseToken();
  }


  return (
    <>

      <footer>
        <table className="w-100 text-center">
          <tbody>
            <tr>
              <td><Link to="/"><i className="fas fa-home"></i></Link></td>
              <td><Link to="/pet-social"><i className="fas fa-paw"></i></Link></td>
              <td><Link to="/my-pets"><i className="fas fa-dog"></i></Link></td>
              <td><Link to="/find-nearby"><i className="far fa-magnifying-glass-location"></i></Link></td>
              <td><i className="fas fa-ellipsis-h" onClick={() => toggle()}></i></td>
            </tr>
            <tr>
              <td><Link to="/">Home</Link></td>
              <td><Link to="/pet-social">Social</Link></td>
              <td><Link to="/my-pets">My Pets</Link></td>
              <td><Link to="/find-nearby">Find</Link></td>
              <td><a role="button" href="#" onClick={() => toggle()}>More</a></td>
            </tr>
          </tbody>
        </table>

      </footer>


      <Offcanvas direction="end" isOpen={showOffscreen} scrollabletoggle={toggle} >
        <OffcanvasBody className="side-menu">
          <table className="w-100 mb-3">
            <tbody>
              <tr>
                <td>
                  <h1>MENU</h1>
                </td>
                <td className="text-end">
                  <button type="button" className="btn-close" onClick={() => toggle() }></button>
                </td>
              </tr>
            </tbody>
          </table>

          <ul>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/my-pets")}>My Pets</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/my-profile")}>My Profile</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/emergency-contacts")}>Emergency Contacts</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/medical")}>Medical</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/imfo")}>Info</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/services")}>Services</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/help-center")}>Help Center</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/app-feedback")}>App Feedback</a></li>
            <li><a className="navMen" href="#" onClick={() => navigateTo("/")}>Install App</a></li>
            <li><a role="button" href="#" onClick={() => forceReload()}>Reload App</a></li>
            <li><a role="button" href="#" onClick={() => enablePushNotifications()}>Enable Push Notifications</a></li>
          </ul>
          <div className="version-info">
            Version {getAppVersion()}
          </div>
        </OffcanvasBody>
      </Offcanvas>

      { /*
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="SideMenu">
        <div className="offcanvas-body side-menu">
          <table className="w-100 my-4">
            <tbody>
              <tr>
                <td>
                  <h1>MENU</h1>
                </td>
                <td className="text-end">
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </td>
              </tr>
            </tbody>
          </table>



          <ul>
            
            <li><a className="navMen" href="#" onClick={() => navigateTo("/my-pets")}>My Pets</a></li>
            <li><Link className="navMen" to="/my-profile">My Profile</Link></li>
            <li><Link className="navMen" to="/emergency-contacts">Emergency Contacts</Link></li>
            <li><Link className="navMen" to="/medical">Medical</Link></li>
            <li><Link className="navMen" to="/imfo">Info</Link></li>
            <li><Link className="navMen" to="/services">Services</Link></li>
            <li><Link className="navMen" to="/help-center">Help Center</Link></li>
            <li><Link className="navMen" to="/app-feedback">App Feedback</Link></li>
            <li><Link className="navMen" to="/">Install App</Link></li>
            <li><a role="button" href="#" onClick={() => forceReload()}>Reload App</a></li>

          </ul>

        </div>
      </div>
      */}
    </>
  );
};

export default Footer;
