import { Component } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../components/Common/SystemIcons';
import Images from '../components/Common/SystemImages';
import PetList from '../components/Pets/pet-list';




export class Home extends Component {
  render() {
    return (
      <>
        <div className="home-banner">
          <img src={Icons.DogFriendlyLogo} alt="Dog Friendly" />
        </div>

        <PetList showAddButton={ false } />

        
        <Link to="/find-nearby" className="btn-home-map">
          <i className="fa-solid fa-location-dot"></i><br />
          Find Nearby...
        </Link>


        <div className="container-fluid mt-4 mb-3">
          <div className="row">
            <div className="col-4 text-center">
              <Link to="/pet-social" className="btn-neu-square">
                <img src={Icons.Social} alt="Social" />
              </Link>
              <p className="text-purple">Social</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/activity" className="btn-neu-square">
                <img src={Icons.Events} alt="Events" />
              </Link>
              <p className="text-purple">Events</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/training" className="btn-neu-square">
                <img src={Icons.Training} alt="Training" />
                <span className="pill"></span>
              </Link>
              <p className="text-purple">Training</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/goals" className="btn-neu-square">
                <img src={Icons.Goals} alt="Goals" />
              </Link>
              <p className="text-purple">Goals</p>
            </div>

            <div className="col-4 text-center">
              <Link to="recipies" className="btn-neu-square">
                <img src={Icons.Recipes} alt="Recipes" />
              </Link>
              <p className="text-purple">Recipes</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/medical" className="btn-neu-square">
                <img src={Icons.Medical} alt="Medical" />
              </Link>
              <p className="text-purple">Medical</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/shopping" className="btn-neu-square">
                <img src={Icons.Shopping} alt="Shopping" />
              </Link>
              <p className="text-purple">Shopping</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/help-center" className="btn-neu-square">
                <img src={Icons.Help} alt="FAQs" />
              </Link>
              <p className="text-purple">FAQs</p>
            </div>

            <div className="col-4 text-center">
              <Link to="/rescues" className="btn-neu-square">
                <img src={Icons.Rescues} alt="Rescues" />
              </Link>
              <p className="text-purple">Rescues</p>
            </div>
          </div>
        </div>


        <div className="mb-3 px-2 d-none">

          <button className="neu-button">Next</button>

          <button className="btn-neo2 ms-3">Next</button>

          <button className="btn-neo2 purple ms-3">Next</button>
        </div>
     

      </>
    )
  }
}
