import DogFriendlyLogo from '../../assets/icons/dog-friendly-logo.png';
import DogSitting from '../../assets/icons/001-dog.png';
import Social from '../../assets/icons/056-friends.png';
import Events from '../../assets/icons/060-star.png';
import Training from '../../assets/icons/017-dog-training-1.png';
import Goals from '../../assets/icons/074-dog-18.png';
import Recipes from '../../assets/icons/045-snack.png';
import Medical from '../../assets/icons/033-dog-10.png';
import Shopping from '../../assets/icons/036-dog-toys.png';
import Help from '../../assets/icons/067-dog-17.png';
import Rescues from '../../assets/icons/072-adopt.png';

import Home from '../../assets/icons/colour/001-dog-house.png';
import WalkingTheDog from '../../assets/icons/colour/007-walking-the-dog.png';
import Food from '../../assets/icons/colour/006-dog-food.png';

const Icons =
{
  DogFriendlyLogo,
  DogSitting,
  Social,
  Events,
  Training,
  Goals,
  Recipes,
  Medical,
  Shopping,
  Help,
  Rescues,

  Home,
  WalkingTheDog,
  Food
}

export default Icons;