import { useEffect, useState } from 'react';
import { Icon } from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import { getAuthToken } from '../helpers/authentication';
import Loader from '../components/Common/Loader';
import { PlaceTypes } from '../helpers/global';
import { getFilterData, setFilterData } from '../helpers/lookup';
import { APIPath } from "../helpers/APIHelper";
import { Inject, CheckBoxSelection, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap'; 

//const datasource = data;


const FindNearby = () => {
  const [mapData, setMapData] = useState();
  const [mapFilteredData, setMapFilteredData] = useState();
  const [loading, setLoading] = useState(true);
  const [mapFilter, setMapFilter] = useState([]);
  const [mapIcons, setMapIcons] = useState([]);
  const [showOffscreen, setShowOffscreen] = useState(false);

  const toggle = () => setShowOffscreen(!showOffscreen);


  const streetMap = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
    maxZoom: 19
  };

  
  const loadData = async () => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/api/places', {
        method: 'GET',
        withCredentials: true,
        //credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //setMapData(data);
        const formattedData = data.map((place) => ({
          ...place,
          placeType: JSON.parse(place.placeType) // Safely parses "[2]" into [2]
        }));
        const filterData = getFilterData();
        setMapFilter(filterData);
        setMapData(formattedData);

        if (filterData.length === 0) {
          setMapFilteredData(formattedData);
        }
        else {
          const filteredData = formattedData.filter((item) =>
            item.placeType.some((type) => filterData.includes(type))
          );
          setMapFilteredData(filteredData);

        }
        setLoading(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  const setIcons = () => {
    const size = [40, 53];
    //21,27
    let icons = [];
    icons.push(new Icon({ iconUrl: '/assets/maps/location-pin-dog-friendly.svg', iconSize: size })); //Unspecified
    icons.push(new Icon({ iconUrl: '/assets/maps/parks.png', iconSize: size })); //Dog Parks
    icons.push(new Icon({ iconUrl: '/assets/maps/shopping.png', iconSize: size })); //Pet Stores
    icons.push(new Icon({ iconUrl: '/assets/maps/trainers.png', iconSize: size })); //Trainers
    icons.push(new Icon({ iconUrl: '/assets/maps/accommodation.png', iconSize: size })); //Accommodation
    icons.push(new Icon({ iconUrl: '/assets/maps/groomers.png', iconSize: size })); //Groomers
    icons.push(new Icon({ iconUrl: '/assets/maps/kennels.png', iconSize: size })); //Kennels
    icons.push(new Icon({ iconUrl: '/assets/maps/restaurant.png', iconSize: size })); //Restaurants
    icons.push(new Icon({ iconUrl: '/assets/maps/beach.png', iconSize: size })); //Beaches
    icons.push(new Icon({ iconUrl: '/assets/maps/camping.png', iconSize: size })); //Camp Sites
    icons.push(new Icon({ iconUrl: '/assets/maps/hikes.png', iconSize: size })); //Hiking Trails
    icons.push(new Icon({ iconUrl: '/assets/maps/vet.png', iconSize: size })); //Vets
    setMapIcons(icons);

  };

  useEffect(() => {
    setIcons();
    loadData();
  }, []);


  const getMapIcon = (placeTypes) => {
    
    try {
      if (placeTypes.length > 0) {
        return mapIcons[placeTypes[0]];
      }
      else
        return mapIcons[0];
    } catch (e) {
      return mapIcons[0];
    }
    
  }

  const applyFilter = () => {
    console.log("in apply filter");
    if (mapFilter.length === 0) {
      setMapFilteredData(mapData);
    }
    else {
      const filteredData = mapData.filter((item) =>
        item.placeType.some((type) => mapFilter.includes(type))
      );
      //console.log(filteredData);
      setMapFilteredData(filteredData);
      
    }
    setFilterData(mapFilter);
    setShowOffscreen(false);
  }

  if (loading)
    return <Loader />;

  
  return (
    <main>
      <div className='text-center pt-2 px-3 d-none'>
        <MultiSelectComponent placeholder="Filter Categories" value={mapFilter} fields={{ text: 'title', value: 'id' }} change={e => setMapFilter(e.value)} dataSource={PlaceTypes} mode="CheckBox" >
          <Inject services={[CheckBoxSelection]} />
        </MultiSelectComponent>
      </div>
      <div className='locationmap-container'>

        <MapContainer center={[-26.0172312, 28.0303184]} zoom={13} scrollWheelZoom={true} className='map' preferCanvas={true} touchZoom={true} zoomAnimation={true}  >
          <TileLayer attribution={streetMap.attribution} url={streetMap.url} maxZoom={streetMap.maxZoom} />
          <MarkerClusterGroup chunkedLoading>
            {mapFilteredData.map((item, index) => (
              <Marker key={index} position={[item.latitude, item.longitude]} icon={getMapIcon(item.placeType)}>
                <Popup>
                  <b>{item.title}</b><br />
                  {item.shortAddress}
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
        <button className="btn-filter" onClick={ () => toggle() }><i className="fa-solid fa-filter"></i></button>

      </div>

      <Offcanvas direction="end" isOpen={showOffscreen} scrollabletoggle={toggle} >
        <OffcanvasBody className="side-menu">
          <table className="w-100 mb-3">
            <tbody>
              <tr>
                <td><h1>Filter</h1></td>
                <td className="text-end">
                  <button type="button" className="btn-close" onClick={() => toggle()}></button>
                </td>
              </tr>
            </tbody>
          </table>

          {
            PlaceTypes.map((item, index) => (
              <label key={index} className="square-checkbox mb-3">
                <input  type="checkbox" value={item.id} id={"placeType" + item.id} checked={mapFilter.includes(item.id)} onChange={e => {
                  if (e.target.checked)
                    setMapFilter([...mapFilter, item.id]);
                  else
                    setMapFilter(mapFilter.filter(x => x !== item.id));
                }} />
                {/*<label className="form-check-label" htmlFor={"placeType" + item.id}>*/}
                  {item.title}
                {/*</label>*/}
              </label>
            ))
          }

          <hr />
          <div className="text-end">
            <button className="btn-neu-primary" onClick={() => applyFilter()}>Apply<i className="far fa-check-circle ms-2"></i></button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </main>
  );
};
export default FindNearby;
