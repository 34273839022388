/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import React, {Fragment} from "react";
import { getAuthToken, GetUserMobile, SetUserMobile } from "./authentication";
import { APIPath } from "./APIHelper";


const bearer = "bearer " + getAuthToken();


export const LoadingSpinner = () => { 
  return (
    <div className="text-center">
      <i className="fas fa-spinner fa-spin fa-2x"></i><br />Loading...
    </div>
  );
}

export const DefaultMapZoom = {zoomFactor: 12, maxZoom: 18, minZoom: 2, enable: true, pinchZooming: true,
  toolbarSettings: {
      buttonSettings: {
          toolbarItems: ['Zoom', 'ZoomIn', 'ZoomOut', 'Pan', 'Reset']
      }
  } }


export const SummariseString = (data, maxCharacters) => {
  if (data && data.length > maxCharacters) {
    return data.substring(0, maxCharacters) + "...";
  } else {
    return data;
  }
}

export const Ordinate = function (data) {
  var str = data.toString().slice(-1),
    ord = '';
  switch (str) {
    case '1':
      ord = 'st';
      break;
    case '2':
      ord = 'nd';
      break;
    case '3':
      ord = 'rd';
      break;
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      ord = 'th';
      break;
  }
  return (<Fragment>{data}<sup>{ord}</sup></Fragment>);
}

export const YesNoTypes = [
  { value: 0, text: '- Select -' },
  { value: 1, text: 'Yes' },
  { value: 2, text: 'No' },
];

export const GenderTypes = [
  { value: 0, text: '- Select -' },
  { value: 2, text: 'Female' },
  { value: 1, text: 'Male' },
  
];


export const PlaceTypes = [
  { title: "Unspecified", id: 0 },
  { title: "Dog Parks", id: 1 },
  { title: "Pet Stores", id: 2 },
  { title: "Trainers", id: 3 },
  { title: "Accommodation", id: 4 },
  { title: "Groomers", id: 5 },
  { title: "Kennels", id: 6 },
  { title: "Restaurants", id: 7 },
  { title: "Beaches", id: 8 },
  { title: "Camp Sites", id: 9 },
  { title: "Hiking Trails", id: 10 },
  { title: "Vets", id: 11 },
];

export const GetContrastColour = (hexColor) => {
  if (hexColor.indexOf("#") >= 0)
    hexColor = hexColor.substr(1);
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  //console.log(hexColor, r,g,b,yiq)
  return (yiq >= 128) ? '#000' : '#fff';
}


