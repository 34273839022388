

import React from 'react';
import { Link } from 'react-router-dom';
import Images from '../Common/SystemImages';
import PropTypes from 'prop-types';

const PetList = ({ showAddButton } ) => {
  return (<>
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-4 text-center mb-3">

          <Link to="/my-pet-profile/abc" className="img-neu-circular">
            <img src={Images.DogPlaceHolder1} alt="Button Image" />
            <span className="pill"></span>
          </Link>
          Laika
        </div>

        <div className="col-4 text-center mb-3">
          <Link to="/my-pet-profile/abc" className="img-neu-circular">
            <img src={Images.DogPlaceHolder2} alt="Button Image" />
          </Link>
          Grogzy
        </div>

        <div className="col-4 text-center mb-3">
          <Link to="/my-pet-profile/abc" className="img-neu-circular">
            <img src={Images.DogPlaceHolder3} alt="Button Image" />
          </Link>
          Nina
        </div>

        {showAddButton && (<div className="col-4 text-center mb-3">
          <Link to="/my-pet-profile/" className="img-neu-circular">
            <i className="fa-solid fa-plus fa-3x"></i>
          </Link>
          Add Pet
        </div>)}
      </div>
    </div>
  </>);
};

PetList.propTypes = {
  showAddButton: PropTypes.bool
};

PetList.defaultProps = {
  showAddButton: false
};

export default PetList;
