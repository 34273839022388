import { Component } from 'react';
import { Outlet } from 'react-router-dom';
import  Footer  from '../Nav/footer';
import { Header } from '../Nav/header';

export class DefaultLayout extends Component {
//const DefaultLayout = () => {

  constructor(props) {
    super(props);

    this.state = {
      showHeader: true,
      showFooter: true
    };
  }

  //onRefresh() {
  //  window.location.reload();
  //}
  

  render() {
    const isHomePage = location.pathname === "/";
    return (
      <>
        {!isHomePage && <Header Heading="Dog Friendly" />}
          <div className='bottom-spacer'>
            <Outlet />
          </div>
          <Footer />

      </>
    );
  }
}

//export default DefaultLayout;
