
const Loader = () => {

  return (
    <>
      <div className='alert alert-loading text-center'><i className='fas fa-spinner fa-spin fa-2x mb-3'></i><br />Please wait, loading data...</div> 
      {/*<div className='alert alert-loading text-center'><i className='fas fa-spinner fa-spin fa-2x mb-3'></i><br />Please wait, loading data...</div>  */}
      {/*<div className="text-center">Loading...</div>
      <div className="loader-main">
        
        <div className="up">
          <div className="loaders">
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
          </div>
          <div className="loadersB">
            <div className="loaderA">
              <div className="ball0"></div>
            </div>
            <div className="loaderA">
              <div className="ball1"></div>
            </div>
            <div className="loaderA">
              <div className="ball2"></div>
            </div>
            <div className="loaderA">
              <div className="ball3"></div>
            </div>
            <div className="loaderA">
              <div className="ball4"></div>
            </div>
            <div className="loaderA">
              <div className="ball5"></div>
            </div>
            <div className="loaderA">
              <div className="ball6"></div>
            </div>
            <div className="loaderA">
              <div className="ball7"></div>
            </div>
            <div className="loaderA">
              <div className="ball8"></div>
            </div>
          </div>
        </div>
      </div>*/}
      




    </>
  )
};
export default Loader;