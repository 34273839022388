import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { APIPath } from "./APIHelper";
import { getAuthToken } from "./authentication";
import { getFirebaseToken, setFirebaseToken } from "./lookup";

export const FirebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyDTy1GuENtoX-XY7NbsIZ-1vta-jFBMU24",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "dog-friendly-42d2e.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "dog-friendly-42d2e",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "dog-friendly-42d2e.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "1066350366897",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:1066350366897:web:597b61b85207f641a05290",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-M70M2PEN07"
});

/*
REACT_APP_FIREBASE_API_KEY=AIzaSyDTy1GuENtoX-XY7NbsIZ-1vta-jFBMU24
REACT_APP_FIREBASE_AUTH_DOMAIN=dog-friendly-42d2e.firebaseapp.com
REACT_APP_FIREBASE_PROJECT_ID = dog-friendly-42d2e
REACT_APP_FIREBASE_STORAGE_BUCKET = dog-friendly-42d2e.firebasestorage.app
REACT_APP_FIREBASE_MESSAGING_SENDER_ID =1066350366897
REACT_APP_FIREBASE_APP_ID = 1:1066350366897:web:597b61b85207f641a05290
REACT_APP_FIREBASE_MEASUREMENT_ID = G-M70M2PEN07
REACT_APP_FIREBASE_VAPID_KEY=BJkqG5Tv0tALpBUWzhLvdWW_6Zvj55IaiMhMk__XLVXqhoWIfeA4IRuN3EDrO-oz3mp0zn347wDQGV9QdGNEInU*/


const Messaging = (async () => {
  try {
    const isBrowserSupported = await isSupported();
    if (isBrowserSupported)
      return getMessaging(FirebaseApp);
    console.log("Firebase not supported this browser");
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
})();

export const GetFirebaseToken = async () => {
  const messageRes = await Messaging;
  if (messageRes) {
    let FirebaseToken = getFirebaseToken();
    console.log(FirebaseToken)
    if (!FirebaseToken || FirebaseToken === "" || FirebaseToken === "undefined")
      await getToken(messageRes, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((CurrentToken) => {
        console.log(CurrentToken);
        setFirebaseToken(CurrentToken);
        //SaveToken(CurrentToken).then((result) => { setFirebaseToken(result) });
      });
  }
}

const SaveToken = async (NewToken) => {
  try {
    let bearer = "bearer " + getAuthToken();
    const response = await fetch(APIPath() + "/global/login/SaveToken/" + NewToken, {
      method: "POST",
      headers: {
        Authorization: bearer,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      let result = await response.json();
      return result;
    } else {
      console.log(response)
    }
    // else
    // SaveToken(NewToken);
  } catch (e) {
    console.error(e);
    // SaveToken(NewToken);
  }
}

export const OnMessageListener = () =>
  new Promise((resolve) => {
    (async () => {
      const messageRes = await Messaging;
      if (messageRes) {
        onMessage(messageRes, (payload) => {
          resolve(payload);
        });
      }
    })()
  });
