import BannerImage from '../../assets/tests/happy-danes.jpg';
import DogPlaceHolder1 from '../../assets/tests/laika.jpg';
import DogPlaceHolder2 from '../../assets/tests/puppy-grogzy.jpg';
import DogPlaceHolder3 from '../../assets/tests/Nina.jpg';

const Images =
{
  BannerImage,
  DogPlaceHolder1,
  DogPlaceHolder2,
  DogPlaceHolder3
}

export default Images;