//import { useEffect, useState } from 'react';

const Medical = () => {
  return (<>
    <h1>Medical</h1>
    <p>Show emergency contacts with call function</p>
    <p>Show Last medical events and upcoming events</p>
    <p>Add a medical event / visit</p>
    <p>show medications</p>
  </>);
};
export default Medical;


